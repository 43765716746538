import React from "react"
import { StaticQuery, graphql } from 'gatsby'
import Flickity from 'react-flickity-component'
import Img from "gatsby-image"

const flickityOptions = {
    prevNextButtons: false,
    pageDots: true,
    cellSelector: '.slider__review-slider-cell',
    wrapAround: true,
    setGallerySize: true,
    cellAlign: 'center',
    autoPlay: 5000,
}

function CreateRatings(p) {
    let ratings = []
    for (let i = 0; i < 5; ++i) {
        if (p.rating > i) {
            ratings.push(<span className="star" key={i}>&#9733;</span>)
        } else {
            ratings.push(<span className="star empty" key={i}>&#9733;</span>)
        }
    }
    return (
        <div>
            {ratings}
        </div>
      )
}

function SliderGoogleReviews(p) {
    return (
        <Flickity
            className={'slider__reviews'}
            elementType={'div'}
            options={flickityOptions}
            disableImagesLoaded={false}
            reloadOnUpdate={true}
            static={true}
        >
            {p.reviewItems.map(({ node }) => {
                let excerpt = node.text.length > 125 ? node.text.substring(0, 125) + "..." : node.text;
                //console.log('node: ', node);
                return (
                    <div key={node.id} className="slider__review-slider-cell">
                        <div className="reviews__item">
                            <figure className="reviews__google">
                                <Img fixed={p.googleImage.large.fixed} alt='Google reviews' />
                            </figure>
                            <p>&ldquo;{excerpt}&rdquo;</p>
                        </div>
                        <span className="reviews__speech">&#9700;</span>
                        <CreateRatings rating={node.rating} />
                        <p className="author">{node.author_name} <small>({node.relative_time_description})</small></p>
                    </div>
                )
            })}
        </Flickity>
    )
}

function GoogleTotalRatings(p) {
    //console.log('p: ', p);
    return (
        <div className="reviews__badge">
            <h4><Img fixed={p.googleImage.small.fixed} alt='Google reviews' /> Google Rating <span>({p.totalRatings})</span></h4>
            <CreateRatings rating={p.totalRatings} />
        </div>
    )
}

export default () => (
    <StaticQuery
        query={graphql`
        {
            googleImage: file(relativePath: { eq: "google_sm.png" }) {
                small: childImageSharp {
                    fixed(width: 15, height: 15) {
                        ...GatsbyImageSharpFixed
                    }
                }
                large: childImageSharp {
                    fixed(width: 30, height: 30) {
                        ...GatsbyImageSharpFixed
                    }
                }
                
            }
            googleTotalRatings {
                total_rating
                total_reviews
            }
            allGoogleReviews {
                edges {
                    node {
                        id
                        author_name
                        author_url
                        relative_time_description
                        rating
                        text
                    }
                }
            }
        }
    `} 
    render = { data => (
        <div className="layout grey">
            <div className="layout__reviews reviews reviews__title">
                <h3>Google Reviews</h3>
                <div className="slider__reviews--container">
                    <SliderGoogleReviews googleImage={data.googleImage} reviewItems={data.allGoogleReviews.edges} />
                </div>
                <div className="reviews__link">
                    <GoogleTotalRatings googleImage={data.googleImage} totalRatings={data.googleTotalRatings.total_rating} /><br />
                    <a href="https://www.google.com/search?q=eden+garage+doors&rlz=1C5CHFA_enNZ773NZ773&oq=eden+garage+doors&aqs=chrome..69i57j46i175i199i433l3j46j69i60l3.3337j0j7&sourceid=chrome&ie=UTF-8#lrd=0x6d0d3925f94fcda3:0xc2edc47876b3314c,3,,," className="reviews__link" rel="noopener nofollow">See all {data.googleTotalRatings.total_reviews} Google reviews &rsaquo;</a>
                </div>
            </div>
        </div>
    )}
    />
)