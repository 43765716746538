import React from "react"
import { Link } from 'gatsby'

export default () => {
    return (
        <div className="layout dark">
            <div className="layout__container">
                <div className="layout__wide copyright center">
                    <p>&copy; 2020 &nbsp; • &nbsp; <Link to='/blog/'>Blog</Link> &nbsp; • &nbsp; <Link to='/terms/'>Terms</Link> &nbsp; • &nbsp; <Link to='/privacy/'>Privacy</Link> &nbsp; • &nbsp; <Link to='/contact/'>Contact</Link> &nbsp; • &nbsp; <a href="tel:0800828299" title="0800 8282 99" rel="nofollow noopener">0800 8282 99</a></p>
                </div>
            </div>
        </div>
    )
}