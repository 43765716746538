import React from "react"
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from "gatsby-image"

class NavMobile extends React.Component 
{
    constructor(props) {
        super(props);
        this.state = {
            showMobile: false,
            navChild: ''
        }
    }

    handleClose = event => {
        event.preventDefault();
		this.setState({
			showMobile: false,
        })
    }
    
    handleOpen = event => {
        event.preventDefault();
		this.setState({
			showMobile: true,
        })
    }

    handleNavChild = event => {
        event.preventDefault();
        let target = event.target.getAttribute('data-id');
        if (this.state.navChild === target) { 
            this.setState({
                navChild: ''
            });
        } else {
            this.setState({
                navChild: target
            })
        }
    }

    render () {
        function getSection (allMarkdownRemarkGroup, section) {
            let result = [];
            for (let edges of allMarkdownRemarkGroup) {
                for (let item of edges.edges) {
                    if (item.node.frontmatter.section === section) {
                        result.push(item.node)
                    }
                }
            }
            result.sort(function(a, b) {
                return a.frontmatter.nav_order - b.frontmatter.nav_order;
            });
            return result;
        }
        
        const MobileNavSection = (p) => <ul className={this.state.navChild === `${p.navChild}` ? `show` : ``}>
            {p.mobileNavSectionItems.map( (p, i) => (<li key={i}><Link to={p.fields.slug}>{p.frontmatter.title}</Link></li>) )}
        </ul>

        const NavSection = (p) => <ul>
            {p.navSectionItems.map( (p, i) => (<li key={i}><Link to={p.fields.slug}>{p.frontmatter.title}</Link></li>) )}
        </ul>

        return (
            <div className="layout">
                <div className={this.state.showMobile ? 'nav__mobile--show' : 'nav__mobile--hide'}>
                    <div className="nav__mobile--overlay"></div>
                    <div className="nav__mobile--close">
                        <button className="nav__mobile--close__link" onClick={this.handleClose}>Close ×</button>
                    </div>
                    <div className="nav__mobile">
                        <nav className="nav__mobile--content">
                            <ul>
                                <li className="nav__child"><button onClick={this.handleNavChild} data-id="child_1" title="Garage Door Repairs">Garage Repairs&nbsp;&nbsp;{this.state.navChild === `child_1` ? `×` : `+`}</button>
                                    <MobileNavSection navChild="child_1" mobileNavSectionItems={getSection(this.props.data.group, 'repairs')} />
                                </li>
                                <li><Link to='/dent-repair/'>Dent Repair</Link></li>
                                <li className="nav__child"><button onClick={this.handleNavChild} data-id="child_2" title="Garage Door Openers">Garage Doors&nbsp;&nbsp;{this.state.navChild === `child_2` ? `×` : `+`}</button>
                                    <MobileNavSection navChild="child_2" mobileNavSectionItems={getSection(this.props.data.group, 'garages')} />
                                </li>
                                <li className="nav__child"><button onClick={this.handleNavChild} data-id="child_3" title="Gallery">Gallery&nbsp;&nbsp;{this.state.navChild === `child_3` ? `×` : `+`}</button>
                                    <MobileNavSection navChild="child_3" mobileNavSectionItems={getSection(this.props.data.group, 'gallery')} />
                                </li>
                                <li><Link to="/contact/">Contact</Link></li>
                                <li><a href="tel:0800828299" title="0800 8282 99" rel="nofollow noopener">0800 8282 99</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="layout grey">
                    <div className="">
                        <div className="layout__wide">
                            <div className="grid grid__1-4 grid__gap--48 align__items">
                                <div className="logo">
                                    <Link to='/'><Img fixed={this.props.logo.childImageSharp.fixed} alt='Eden Garage Doors' /></Link>
                                </div>
                                <div className="nav nav__off">
                                    <ul className="sf__menu">
                                        <li className="nav__dropdown"><Link to='/repair-services/' activeClassName="active" partiallyActive={true} className="nav__arrow">Garage Repairs &nbsp;<img src="/img/drop__down.svg" alt="arrow"/></Link>
                                            <NavSection navSectionItems={getSection(this.props.data.group, 'repairs')} />
                                        </li>
                                        <li><Link to='/dent-repair/' activeClassName="active">Dent Repair</Link></li>
                                        <li className="nav__dropdown"><Link to='/garage-door-openers/' activeClassName="active" partiallyActive={true} className="nav__arrow">Garage Doors &nbsp;<img src="/img/drop__down.svg" alt="arrow"/></Link>
                                            <NavSection navSectionItems={getSection(this.props.data.group, 'garage')} />
                                        </li>
                                        <li className="nav__dropdown"><Link to='/gallery/' activeClassName="active" partiallyActive={true} className="nav__arrow">Gallery &nbsp;<img src="/img/drop__down.svg" alt="arrow"/></Link>
                                            <NavSection navSectionItems={getSection(this.props.data.group, 'gallery')} />
                                        </li>
                                        <li><Link to='/about/' activeClassName="active">About</Link></li>
                                        <li><Link to='/contact/' activeClassName="active">Contact</Link></li>
                                        <li><a href="tel:0800828299" title="0800 8282 99" rel="nofollow noopener">0800 8282 99</a></li>
                                    </ul>
                                </div>
                                <div className="nav__top nav__mobile__on">
                                    <ul>
                                        <li><a href="tel:0800828299" title="0800 8282 99" rel="nofollow noopener">0800 8282 99</a></li>
                                        <li className="nav__button"><button title="Menu" onClick={this.handleOpen} className="nav__hamburger--toggle">&#9776; Menu</button></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
  
export default () => (
    <StaticQuery
        query={graphql`
            query {
                logo: file(relativePath: { eq: "logo-eden-garage-doors.png" }) {
                    childImageSharp {
                        fixed(width: 100) {
                            ...GatsbyImageSharpFixed
                          }
                    }
                }
                allMarkdownRemark {
                    group(field: frontmatter___section) {
                        edges {
                            node {
                                fields {
                                    slug
                                }
                                frontmatter {
                                    title
                                    section
                                    nav_order
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={(data) => (
            <NavMobile logo={data.logo} data={data.allMarkdownRemark} />
        )}
    />
)